
  import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
  import { FormInput, FormSelect, FormCheckbox, FormPhone } from "@/components/forms";
  import { mask } from "vue-the-mask";
  import { maritalStateOptions, countryOptions, titleOptions } from "@/utils/select-options";

  interface Representative {
    not_all_information_known?: boolean;
    title: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    national_register_number: string;
    birth_date: string;
    birth_place: string;
    marital_state: string;
    address: {
      street: string;
      number: string | null;
      additional: string | null;
      zip: string;
      city: string;
      country: string;
    };
  }

  @Component({
    components: {
      FormInput,
      FormSelect,
      FormCheckbox,
      FormPhone,
    },
    directives: { mask },
  })
  export default class FormRepresentatives extends Vue {
    @Prop() value?: Representative[] | null;

    @Prop({ required: true }) name!: string;

    @Prop({ default: false }) label!: string;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({
      default: () => {
        return { street: "", number: "", additional: "", zip: "", city: "", country: "" };
      },
    })
    defaultAddress!: { street: string; number: string | null; additional: string | null; zip: string; city: string; country: string };

    copyAddressFromDefault: boolean[] = [];

    maritalStateOptions = maritalStateOptions;

    titleOptions = titleOptions;

    countryOptions = countryOptions;

    localValue: Representative[] = [
      {
        not_all_information_known: false,
        title: "",
        first_name: "",
        last_name: "",
        national_register_number: "",
        birth_date: "",
        birth_place: "",
        marital_state: "",
        phone: "",
        email: "",
        address: {
          street: "",
          number: "",
          additional: "",
          zip: "",
          city: "",
          country: "",
        },
      },
    ];

    addRepresentative() {
      this.localValue!.push({
        not_all_information_known: false,
        title: "",
        first_name: "",
        last_name: "",
        national_register_number: "",
        birth_date: "",
        birth_place: "",
        marital_state: "",
        phone: "",
        email: "",
        address: {
          street: "",
          number: "",
          additional: "",
          zip: "",
          city: "",
          country: "",
        },
      });
    }

    removeRepresentative(index: number) {
      this.localValue!.splice(index, 1);
    }

    handleRegisterNumberChange(index: number) {
      let NrNValidation = this.validateNRN(this.localValue[index].national_register_number.replaceAll(".", "").replaceAll("-", "").replaceAll(" ", ""));
      if (NrNValidation.valid) {
        let decade = NrNValidation.decade;
        let [year, month, day] = this.localValue[index].national_register_number.slice(0, 8).split(".");
        this.localValue[index].birth_date = `${decade}${year}-${month}-${day}`;
      }
    }

    validateNRN(n: string) {
      if (n.length != 11) return { valid: false };

      var checkDigit = n.substr(n.length - 2, 2);
      var nrToCheck = parseInt(n.substr(0, 9));

      //@ts-ignore
      if (this.modFunction(nrToCheck) == checkDigit) return { valid: true, decade: 19 };

      nrToCheck = parseInt("2" + n.substr(0, 9));

      //@ts-ignore
      if (this.modFunction(nrToCheck) == checkDigit) {
        return { valid: true, decade: 20 };
      }

      return { valid: false };
    }

    modFunction(nr: any) {
      return 97 - (nr % 97);
    }

    @Emit("input")
    handleInput(v: boolean) {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: Representative[] | null) {
      this.localValue = newValue ?? [
        {
          title: "",
          first_name: "",
          last_name: "",
          national_register_number: "",
          birth_place: "",
          birth_date: "",
          marital_state: "",
          phone: "",
          email: "",
          address: {
            street: "",
            number: "",
            additional: "",
            zip: "",
            city: "",
            country: "",
          },
        },
      ];

      this.validateIfAddressIsDefault();
    }

    validateIfAddressIsDefault() {
      if (this.defaultAddress) {
        this.localValue.forEach((representative, index) => {
          if (
            representative.address.street === this.defaultAddress.street &&
            representative.address.zip === this.defaultAddress.zip &&
            representative.address.city === this.defaultAddress.city
          ) {
            this.copyAddressFromDefault[index] = true;
          }
        });
      }
    }

    setDefaultAddress(value: boolean, index: number) {
      if (!this.localValue[index] || !value) {
        return;
      }

      this.localValue[index].address = this.defaultAddress;
    }
  }
